<style>
ิh1,
h2,
h3,
h4 {
  color: black;
}
</style>
<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Buttons from '../ui/buttons.vue';
// import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "บอร์ดสถานะงานซ่อม",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    // Multiselect,
    // DatePicker,
  },
  data() {
    return {
      title: "บอร์ดสถานะงานซ่อม",
      items: [
        {
          text: "บริการ",
        },
        {
          text: "บอร์ดสถานะงานซ่อม",
          active: true,
        },
      ],

      loading: undefined,
      form: {},

      branchId: "",
      linkPtintFlowslot: "",
      optionsBranch: [
        {
          branchId: 1,
          branNameTh: "สุริยะไอที สำนักงานใหญ่",
        },
      ],
      localDataBranch: [], //เป็น-array Id
      localData: [],
      DateSearch: "",
      rowSlot: [],
      rowStatusBoard: [],

      countRowSlot: "",

      //นับสถานะ
      timer: "",
      cPARK: "",
      cCIN: "",
      cCOUT: "",
      cSW: "",
      cFW: "",
      cSP: "",
      cFP: "",
      cOUT: "",

      colSp: 4,
      fslot1: false,

      Col1: false,
    };
  },
  computed: {},

  created() {
    this.getNow();
    this.getLocalData();
    this.getDataStatusBoard();
    this.timer = setInterval(this.fetchEventsList, 300000);
  },

  methods: {
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      // console.log(user);
    },
    styleStatusPark(statusPARK) {
      if (statusPARK == "PARK") {
        return "color:green;";
      } else {
        return "color:green";
      }
    },
    styleStatusCIN(statusCIN) {
      if (statusCIN == "PARK") {
        return "";
      } else if (statusCIN == "CIN") {
        return "color:orange;";
      } else {
        return "color:green";
      }
    },
    styleStatusWash(statusWash) {
      if (statusWash == "PARK") {
        return "";
      } else if (statusWash == "CIN") {
        return "";
      } else if (statusWash == "SW") {
        return "color:orange";
      } else if (statusWash == "FW") {
        return "color:green";
      } else if (statusWash == "SP") {
        return "color:green";
      } else {
        return "color:green";
      }
    },
    styleStatusPay(statusPay) {
      if (statusPay == "PARK") {
        return "";
      } else if (statusPay == "CIN") {
        return "";
      } else if (statusPay == "SW") {
        return "";
      } else if (statusPay == "FW") {
        return "";
      } else if (statusPay == "SP") {
        return "color:orange";
        
      } else {
        return "color:green";
      }
    },
    styleStatusOUT(status) {
      if (status == "PARK") {
        return "";
      } else if (status == "CIN") {
        return "";
      } else if (status == "SW") {
        return "";
      } else if (status == "FW") {
        return "";
      } else if (status == "SP") {
        return "";
      } else if (status == "OUT") {
        return "color:green";
      } else {
        return "";
      }
    },

    cancelAutoUpdate() {
      clearInterval(this.timer);
    },

    getDataStatusBoard: function() {
      this.loading = true;
      useNetw
        .get("api/status-board", {
          params: {
            branchId: this.branchId,
            // branchId:this.localDataBranch,
            date: this.DateSearch,
            // date: "2021-07-17",
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowStatusBoard = response.data.data;
          console.log("rowStatusBoard ====== > ", this.rowStatusBoard);
          // this.fetchEventsList();
          this.getTotalStatus();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.DateSearch = date;
      // console.log(this.DateSearch);
    },
    getTotalStatus() {
      this.cPARK = this.rowStatusBoard.filter((x) => x.status == "PARK").length;
      this.cCIN = this.rowStatusBoard.filter((x) => x.status == "CIN").length;
      this.cCOUT = this.rowStatusBoard.filter((x) => x.status == "COUT").length;
      this.cSW = this.rowStatusBoard.filter((x) => x.status == "SW").length;
      this.cFW = this.rowStatusBoard.filter((x) => x.status == "FW").length;
      this.cSP = this.rowStatusBoard.filter((x) => x.status == "SP").length;
      this.cFP = this.rowStatusBoard.filter((x) => x.status == "FP").length;
      this.cOUT = this.rowStatusBoard.filter((x) => x.status == "OUT").length;

      // console.log(this.cCOUT);
      // console.log('PARK = ',this.cPARK);
      // console.log('CIN = ',this.cCIN);
    },
    getReportFlowSlot() {
      this.loading = true;
      useNetw
        .get("api/flow-slot/print-flow-slot", {
          params: {
            branchId: this.branchId,
            date: this.DateSearch,
          },
        }) // ?เอามาจากไหน
        .then((data) => {
          // console.log(data.data.url);
          this.linkPtintFlowslot = data.data.url;
          // this.$refs["modalPrintflowSlot"].show();
          // window.open(data.data.url, '_blank');
          window.location = (data.data.url);
          // window.open = data.data.url;
          // this.loading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูลรถในวันที่ " + this.DateSearch),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  middleware: "authentication",
};
</script>

<template>
  <!-- <Layout> -->
  <!-- <PageHeader :title="title" :items="items" /> -->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-skeleton-table
                :rows="5"
                :columns="6"
                :table-props="{ bordered: false, striped: true }"
                animation="throb"
              ></b-skeleton-table>
            </template>
            <div class="row">
              <div class="col-md-2">
                <router-link :to="{ name: 'home' }">
                  <a>กลับสู่หน้าหลัก</a>
                </router-link>
              </div>
              <div class="col-md-10 text-end">
                <button class="btn btn-primary" @click="getReportFlowSlot()">
                  <i class="uil-print"></i>
                  <!-- @click="getReportFlowSlot()" -->
                  พิมพ์รายงานการเข้าซ่อมประจำวัน
                </button>
              </div>
            </div>

            <hr />
            <h3>
              <marquee direction="left">
                จำนวนรถเข้าบริการ : {{ rowStatusBoard.length }} คัน ( walk in :
                0 คัน / Booking : {{ rowStatusBoard.length }} คัน ) , สถานะ (
                เข้าจอด {{ cPARK }} คัน / กำลังซ่อม {{ cCIN }} คัน / กำลังล้าง
                {{ cFW }} คัน / ส่งมอบ {{ cOUT }} คัน / )
              </marquee>
            </h3>

            <div class="table-responsive">
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <th colspan="7" class="text-center">
                      <h2><b>สถานะรถเข้าศุูนย์บริการ</b></h2>
                    </th>
                  </tr>
                  <tr>
                    <th rowspan="2" class="text-center">
                      <h2><b>ทะเบียนรถ</b></h2>
                    </th>
                    <th colspan="5" class="text-center">
                      <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สถานะรถ</h2>
                    </th>
                    <th rowspan="2" class="text-center ">
                      <h2><b>เวลาเสร็จ</b></h2>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-center"><h3>เข้าจอด</h3></th>
                    <th class="text-center"><h3>ซ่อม</h3></th>
                    <th class="text-center"><h3>ล้างรถ</h3></th>
                    <th class="text-center"><h3>ชำระเงิน</h3></th>
                    <th class="text-center"><h3>พร้อมส่งมอบ</h3></th>

                    <!-- <th :colspan="colSp" class="text-center">17.00</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(itemSTB, index) in rowStatusBoard" :key="index">
                    <td class="text-center">
                      <h2>
                        <b>{{ itemSTB.licensePlate }} </b>
                      </h2>
                      {{ itemSTB.modelNameTh }}
                    </td>

                    <td class="text-center" v-if="itemSTB.status === 'PARK'">
                      <b style="font-size:70px;margin:0;"
                        ><i
                          class="fas fa-parking"
                          :style="styleStatusPark(itemSTB.status)"
                        ></i
                      ></b>
                    </td>
                    <td class="text-center" v-else>
                      <b style="font-size:70px;margin:0;"
                        ><i
                          class="fas fa-parking"
                          :style="styleStatusPark(itemSTB.status)"
                        ></i
                      ></b>
                    </td>

                    <td class="text-center" v-if="itemSTB.status === 'CIN'">
                      <b style="font-size:70px;margin:0;"
                        ><i
                          class="mdi mdi-progress-wrench"
                          :style="styleStatusCIN(itemSTB.status)"
                        ></i
                      ></b>
                    </td>

                    <td
                      v-else-if="itemSTB.status === 'COUT'"
                      class="text-center"
                    >
                      <b style="font-size:70px;margin:0;"
                        ><i
                          class="mdi mdi-progress-wrench"
                          :style="styleStatusCIN(itemSTB.status)"
                        ></i
                      ></b>
                    </td>
                    <td class="text-center" v-else>
                      <b style="font-size:70px"
                        ><i
                          class="mdi mdi-progress-wrench"
                          :style="styleStatusCIN(itemSTB.status)"
                        ></i
                      ></b>
                    </td>

                    <td class="text-center" v-if="itemSTB.status === 'FW'">
                      <b style="font-size:70px;"
                        ><i
                          class="mdi mdi-car-wash"
                          :style="styleStatusWash(itemSTB.status)"
                        ></i
                      ></b>
                    </td>
                    <td v-else-if="itemSTB.status === 'SW'" class="text-center">
                      <b style="font-size:70px;"
                        ><i
                          class="mdi mdi-car-wash"
                          :style="styleStatusWash(itemSTB.status)"
                        ></i
                      ></b>
                    </td>
                    <td class="text-center" v-else>
                      <b style="font-size:70px"
                        ><i
                          class="mdi mdi-car-wash"
                          :style="styleStatusWash(itemSTB.status)"
                        ></i
                      ></b>
                    </td>

                    <td class="text-center" v-if="itemSTB.status === 'SP'">
                      <b style="font-size:70px;"
                        ><i
                          class="fas fas fa-money-check-alt"
                          :style="styleStatusPay(itemSTB.status)"
                        ></i
                      ></b>
                    </td>
                    <td v-else-if="itemSTB.status === 'FP'" class="text-center">
                      <b style="font-size:70px;"
                        ><i
                          class="fas fas fa-money-check-alt"
                          :style="styleStatusPay(itemSTB.status)"
                        ></i
                      ></b>
                    </td>
                    <td class="text-center" v-else>
                      <b style="font-size:70px"
                        ><i
                          class="fas fas fa-money-check-alt"
                          :style="styleStatusPay(itemSTB.status)"
                        ></i
                      ></b>
                    </td>

                    <td class="text-center" v-if="itemSTB.status === 'OUT'">
                      <b style="font-size:70px;"
                        ><i
                          class="mdi mdi-car-arrow-right"
                          :style="styleStatusOUT(itemSTB.status)"
                        ></i
                      ></b>
                    </td>
                    <td class="text-center" v-else>
                      <b style="font-size:70px"
                        ><i
                          class="mdi mdi-car-arrow-right"
                          :style="styleStatusOUT(itemSTB.status)"
                        ></i
                      ></b>
                    </td>

                    <th class="text-center">
                      <h2>{{ itemSTB.expectFinishTime }}</h2>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-skeleton-wrapper>

          <b-modal
            title="เอกสารรายงานรถ"
            hide-footer
            size="sm"
            centered
            ref="modalPrintflowSlot"
          >
            <div class="row">
              <div class="col text-center">
                <label for="amount"
                  ><code>*</code> วันที่ : {{ DateSearch }} <br />
                  ผู้จำหน่าย : {{ branchId }}
                </label>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-lg-12">
                <div class="text-center">
                  <a class="btn btn-success" :href="this.linkPtintFlowslot">
                    Print
                  </a>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>

  <!-- </Layout> -->
</template>
